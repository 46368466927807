<template>
  <v-dialog
    scrollable
    :value="value"
    @click:outside="$emit('close')"
    max-width="500"
  >
    <v-card>
      <v-card-title class="pt-3">
        {{ title }}
      </v-card-title>
      <v-card-subtitle class="pb-3">
        {{ subTitle }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text class="py-0">
        <FilterList :filters="filterList" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-container
          fluid
          class="py-0"
        >
          <v-row dense>
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                large
                @click="$emit('close')"
              >
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                large
                @click="resetFilters"
              >
                {{ $t('reset') }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                block
                large
                color="primary"
                @click="applyFilters"
              >
                {{ $t('apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FilterList from "@/components/universalFilter/FilterList"

export default {
	name: "UniversalFilter",
	components: {
		FilterList
	},
	props: {
		value: {
      type: Boolean
    },
		filterList: {
      type: Array,
      default: () => []
    },
		title: {
      type: String,
      default: '',
    },
		subTitle: {
      type: String,
      default: ''
    }
	},
	data() {
		return {
		}
	},
	methods: {
		resetFilters() {
			return null
		},
		applyFilters() {
			this.$emit('applyFilters')
		}
	}
}
</script>

<style scoped>

</style>